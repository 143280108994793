import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
// import Page from "./Containers/Page/Page";
// import Admin from "./Containers/Admin/Admin";
// import Login from "./Containers/Login/Login";
// import Pages from "./Containers/Admin/Pages/Pages";
// import Account from "./Containers/Admin/Account/Account";
import Feed from "./Components/Feed/Feed";
import Logout from "./Containers/Admin/Logout/Logout";
import * as actions from "./store/actions/action";
import { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import LoadingPage from "./Components/LoadingPage/LoadingPage";

const Page = lazy(() => import("./Containers/Page/Page"));
const Admin = lazy(() => import("./Containers/Admin/Admin"));
const Login = lazy(() => import("./Containers/Login/Login"));
const Home = lazy(() => import("./Containers/Home/Home"));
const Pages = lazy(() => import("./Containers/Admin/Pages/Pages"));
const Account = lazy(() => import("./Containers/Admin/Account/Account"));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path=""
        element={
          <Suspense fallback={<LoadingPage />}>
            <Home />
          </Suspense>
        }
      />
      <Route path="page/:username/:id" element={<Page />} />
      <Route path="page/:id" element={<Page />} />
      <Route
        path="admin"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Admin />
          </Suspense>
        }
      >
        <Route
          path="page/:id"
          element={
            <ProtectedRoute>
              <Feed editmode />
            </ProtectedRoute>
          }
        />
        <Route
          path="pages"
          element={
            <ProtectedRoute>
              <Suspense fallback={<LoadingPage />}>
                <Pages />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path="account"
          element={
            <ProtectedRoute>
              <Suspense fallback={<LoadingPage />}>
                <Account />
              </Suspense>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/login"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Login />
          </Suspense>
        }
      />
      <Route path="/admin/logout" element={<Logout />} />
    </Route>
  )
);

function App({ token, setToken }) {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
  }, []);
  return <RouterProvider router={router} />;
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch(actions.TokenSetter(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
