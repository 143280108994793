import {
  ArrowLeftOutlined,
  CopyOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import api from "../../api/api";
import Post from "../Post/Post";
import { Button, Card, Popover, Skeleton, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PostForm from "../PostForm/PostForm";
import styles from "./Feed.module.css";
import { connect } from "react-redux";

function Feed({ editmode, token }) {
  const [posts, setPosts] = useState([...new Array(18).fill(1)]);
  const [search, setSearch] = useState("");
  const [newPostModal, setNewPostModal] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const headers = {
    Authorization: "Token " + token,
    "content-type": "multipart/form-data",
  };
  const loadData = () => {
    api
      .get(`/page/${id}`)
      .then((res) => {
        const data = res.data;
        setPosts(data.posts);
        setAvatar(data.avatar);
        setName(data.name);
        setUserName(data.instagram_username);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createPost = (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("image", values.image);
    fd.append("title", values.title);
    fd.append("links", JSON.stringify(values.links));
    fd.append("description", values.description);
    fd.append("page", id);

    api
      .post("/posts", fd, { headers })
      .then((res) => {
        setLoading(false);
        setNewPostModal(null);
        const newPosts = structuredClone(posts);
        setPosts([res.data, ...newPosts]);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const updatePost = (values) => {
    setLoading(true);
    const fd = new FormData();
    if (values.image) {
      fd.append("image", values.image);
    }
    fd.append("title", values.title);
    fd.append("links", JSON.stringify(values.links));
    fd.append("description", values.description);
    fd.append("page", id);

    api
      .patch(`/post/${editModal.id} `, fd, { headers })
      .then((res) => {
        setLoading(false);
        setEditModal(null);
        const newPosts = structuredClone(posts).map((el) => {
          if (`${el.id}` === `${editModal.id}`) {
            return res.data;
          }
          return el;
        });
        setPosts(newPosts);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const deletePost = () => {
    setLoading(true);
    api
      .delete(`/post/${editModal.id}`, { headers })
      .then((res) => {
        const newPosts = [];
        posts.forEach((el) => {
          if (`${el.id}` !== `${editModal.id}`) {
            newPosts.push(el);
          }
        });
        setPosts(newPosts);
        setLoading(false);
        setEditModal(null);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className={styles.Feed}>
      {editmode && ( userName ? (
        <div className={styles.PreviewLink}>
          <div>
            https://feedlink.ir/page/{userName}/{id}
          </div>
          <div className={styles.PreviewLinkButtons}>
            <Popover content="کپی شد!" placement="left">
              <Button
                icon={<CopyOutlined />}
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://feedlink.ir/page/${userName}/${id}`
                  );
                }}
              />
            </Popover>
          </div>
        </div>
      ) : (
        <Skeleton.Button
          active
          className={[styles.PreviewLink, styles.PreviewLinkSkeleton]}
        />
      ))}
      <Card bodyStyle={{ padding: 16 }} className={styles.Header}>
        <div className={styles.Content}>
          <Card.Meta
            avatar={
              <>
                {isLoaded ? (
                  <div className={styles.AvatarContainer}>
                    {editmode && (
                      <Button
                        type="text"
                        onClick={() => {
                          navigate("/admin/pages");
                        }}
                        icon={<RightOutlined />}
                      />
                    )}
                    <img
                      src={avatar}
                      className={styles.Avatar}
                      alt="Logo"
                      height="48"
                      width="48"
                    />
                  </div>
                ) : (
                  <Skeleton.Avatar
                    active
                    size="large"
                    style={{ height: "3.5rem", width: "3.5rem" }}
                  />
                )}
              </>
            }
            title={name ? name : <Skeleton.Input size="small" active />}
            description={
              userName ? (
                `${userName}@`
              ) : (
                <Skeleton.Input
                  style={{ marginTop: "0.5rem" }}
                  size="small"
                  active
                />
              )
            }
          />
        </div>
      </Card>
      <div className={styles.SearchInputContainer}>
        <input
          disabled={loading}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className={styles.SearchInput}
          placeholder="جستجو"
        />
        <Search className={styles.SearchIcon} />
      </div>
      <div className={styles.Posts}>
        {Array.isArray(posts) &&
          posts.map((post) => {
            if (!`${post.title}`.includes(search)) {
              return null;
            }
            return (
              <Post
                onEdit={() => {
                  setEditModal(post);
                }}
                editmode={editmode}
                {...post}
              />
            );
          })}
      </div>

      {editmode && (
        <>
          <PostForm
            title="ساخت پست جدید"
            open={newPostModal}
            onFinish={createPost}
            onClose={() => {
              setNewPostModal(false);
            }}
            loading={loading}
          />
          <PostForm
            onClose={() => {
              setEditModal(null);
            }}
            onFinish={updatePost}
            open={editModal ? true : false}
            title={`ویرایش پست "${editModal ? editModal.title : null}"`}
            data={editModal}
            loading={loading}
            onDelete={deletePost}
          />
        </>
      )}
      {editmode && (
        <footer className={styles.EditBoard}>
          <Button
            shape="square"
            type="primary"
            className={styles.NewPostButton}
            icon={<PlusOutlined />}
            onClick={() => {
              setNewPostModal(true);
            }}
          >
            پست جدید
          </Button>
        </footer>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    // userData: state.userData,
  };
};
export default connect(mapStateToProps)(Feed);
