export const SIGNUP_START = 'SIGNUP_START';
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_INIT = 'SIGNIN_INIT';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const LOGOUT = 'LOGOUT';
export const ERROR_SETTER = 'ERROR_SETTER';
export const LOADING_SETTER = 'LOADING_SETTER';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const TOKEN_SETTER = 'TOKEN_SETTER';


