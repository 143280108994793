import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as actions from "../../../store/actions/action";
import { connect } from "react-redux";
const Logout = ({ onLogout }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (onLogout) {
      onLogout();
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, [navigate, onLogout]);
  return;
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.Logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
