import { Button, Form, Input, Modal, Popconfirm, Space, Upload, message } from "antd";
import {
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import styles from "./PostForm.module.css";
const PostForm = ({
  data,
  title,
  open,
  onClose,
  onFinish,
  onDelete,
  loading,
}) => {
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      form.setFieldValue("image", null);
      setImage(data.image);
    }
  }, [data]);
  useEffect(() => {
    if (!open) {
      form.resetFields();
      setImage(null);
    }
  }, [open]);
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onClose}
      footer={[]}
      destroyOnClose
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        className={styles.PostForm}
        disabled={loading}
      >
        <Form.Item
          label="عکس کاور"
          required
          className={styles.FormItem}
          name="image"
          rules={!data && [
            {
              required: true,
            },
          ]}
        >
          <div
            className={styles.ImageContainer}
            style={{ display: "flex", flexDirection: "row" }}
          >
            {image && (
              <img className={styles.Image} alt="" width="50%" src={image} />
            )}
            <ImgCrop showGrid modalTitle="برش عکس">
              <Upload.Dragger
                style={{ width: "100%" }}
                customRequest={({
                  file,
                  onSuccess,
                  onProgress,
                  onError,
                  filename,
                }) => {
                  form.setFieldValue("image", file);
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    setImage(reader.result);
                    onSuccess();
                  };
                }}
                showUploadList={false}
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {image ? "تغییر عکس" : "آپلود عکس"}
                </p>
              </Upload.Dragger>
            </ImgCrop>
          </div>
        </Form.Item>
        <Form.Item
          className={styles.FormItem}
          name="title"
          label="عنوان"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.FormItem}
          name="description"
          label="توضیحات"
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.FormItem}
          label="لینک‌"
          required
          extra="از یک تا 4 لینک می‌توانید برای هر پست بسازید."
    
        >
          <Form.List
            rules={[
              {
                required: true,
                message: "لینک اجباری است!",

              },
            ]}
            className={styles.FormItem}
            name="links"
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      columnGap: 6,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      className={styles.FormItem}
                      name={[name, "title"]}
                      rules={[
                        {
                          required: true,
                          message: "عنوان خالی است",
                        },
                      ]}
                    >
                      <Input
                        placeholder="عنوان"
                        style={{ textAlign: "center" }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: "60%", direction: "ltr" }}
                      className={styles.FormItem}
                      name={[name, "link"]}
                      rules={[
                        {
                          required: true,
                          message: "لینک اشتباه است",
                          pattern : /^https?:\/\//i
                        },
                      ]}
                    >
                      <Input
                        style={{ direction: "ltr", textAlign: "center" }}
                        placeholder="https://"
                      />
                    </Form.Item>
                    <Button
                      onClick={() => remove(name)}
                      icon={<MinusCircleOutlined />}
                    />
                  </div>
                ))}
                <Form.Item className={styles.FormItem}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={
                      <PlusOutlined
                        style={{ position: "relative", top: "0.125rem" }}
                      />
                    }
                  >
                    لینک جدید
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item className={styles.FormItem} style={{ margin: 0 }}>
          <Button
            loading={loading}
            htmlType="submit"
            style={{ width: onDelete ? "calc(50% - 0.25rem)" : "100%" }}
            type="primary"
            onClick={()=>{
              const links = form.getFieldValue('links')
              if(Array.isArray(links) && links.length < 1) {
                message.error('!شما هیچ لینکی برای پست نساخته‌اید')
              }
            }}
          >
            {data ? "ذخیره" : "ساخت"}
          </Button>
          {onDelete && (
            <Popconfirm
              onConfirm={onDelete}
              title="آیا از حذف پست مطمئن هستید؟"
            >
              <Button
                loading={loading}
                style={{
                  width: onDelete ? "calc(50% - 0.25rem)" : "100%",
                  marginRight: "0.5rem",
                }}
                danger
              >
                حذف پست
              </Button>
            </Popconfirm>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default PostForm;
