import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import faIR from 'antd/locale/fa_IR';
import { Provider } from 'react-redux';
import store from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
const rootTheme = {
  token: {
    // Seed Token
    colorPrimary: "rgb(50 50 50)",
    colorBgContainer: "white",
    controlItemBgActive: "whitesmoke",
  },
  components: {
    Select: {
      controlItemBgActive: "whitesmoke",
      colorBorderBg : 'whitesmoke'
    },
  },
};
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ConfigProvider theme={rootTheme} direction='rtl' locale={faIR}>
        <App />
      </ConfigProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
