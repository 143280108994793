import api from '../../api/api';
import * as actionTypes from './actionTypes';


export const StartGetUserData = (token) => {
    return dispatch => {
        api.get('/me', {
            headers: {
                Authorization: 'Token ' + token
            }
        })
            .then(res => {
                dispatch(GetUserDataSuccess(res.data))

            }
            )
            .catch(error => {
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.detail === 'Token is expired!'
                            || error.response.data.detail === 'Invalid token.') {
                            dispatch(Logout());
                        }
                    }

                }

            })
    }
}

const GetUserDataSuccess = (userData) => {
    return {
        type: actionTypes.GET_USER_DATA_SUCCESS,
        userData: userData,
    }
}

export const StartChangeUserAvatar = (avatar, token) => {
    return dispatch => {
        dispatch(LoadingSetter());
        const fd = new FormData();
        fd.append('avatar', avatar)
        api.patch('/me', fd, {
            headers: {
                Authorization: 'Token ' + token,
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: ProgressEvent => {

                // console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100));
            }

        })
            .then(
                res => {
                    dispatch(GetUserDataSuccess(res.data));
                    document.location.reload();
                })
            .catch(
                err => {
                    dispatch(ErrorSetter(err, 'ChangeAvatar'))
                })

    }
}
export const StartChangeUserData = (name, gender, birthDate, token) => {
    return dispatch => {
        dispatch(LoadingSetter());
        api.patch('/me', {
            'name': name,
            'gender': gender,
            'birth_date': birthDate
        },
            {
                headers: {
                    Authorization: 'Token ' + token
                }
            })
            .then(
                res => {
                    dispatch(GetUserDataSuccess(res.data))
                }
            )
            .catch(
                err => {
                    dispatch(ErrorSetter(err, 'ChangeUserData'))
                }
            )
    }
}
export const StartChangeUserPassword = (LastPassword, NewPassword, token) => {
    return dispatch => {
        dispatch(LoadingSetter());
        api.post('/me/changepass', {
            'old_pass': LastPassword,
            'new_pass': NewPassword,

        },
            {
                headers: {
                    Authorization: 'Token ' + token
                }
            })
            .then(
                res => {
                    dispatch(ClearLoading())
                }
            )
            .catch(
                err => {
                    dispatch(ErrorSetter(err, 'ChangePassword'))
                }
            )
    }
}
export const LoadingSetter = () => {
    return {
        type: actionTypes.LOADING_SETTER
    }
}
export const ClearLoading = () => {
    return {
        type: actionTypes.CLEAR_LOADING,
    }
}
export const ErrorSetter = (error, ErrorType) => {
    return {
        type: actionTypes.ERROR_SETTER,
        error: error,
        ErrorType: ErrorType
    }
}
export const ClearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR
    }
}
export const Logout = () => {
    return {
        type: actionTypes.LOGOUT,
    }
}
export const TokenSetter = (token) => {
    return {
        type: actionTypes.TOKEN_SETTER,
        token
    }
}
