import * as actionTypes from "../actions/actionTypes";
const initialState = {
  token: null,
  loading: false,
  userData: null,
  error: null,
  ErrorType: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        loading: false,
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        ErrorType: null,
      };
    case actionTypes.LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        redirectPath: null,
        userData: null,
      };
    case actionTypes.LOADING_SETTER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ERROR_SETTER:
      return {
        ...state,
        error: action.error,
        ErrorType: action.ErrorType,
        loading: false,
      };
    case actionTypes.CLEAR_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TOKEN_SETTER:
      localStorage.setItem("token", action.token);
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
};

export default reducer;
