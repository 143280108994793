import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import * as actions from '../../store/actions/action'
const ProtectedRoute = ({ children, token , setToken}) => {
  const local_token = localStorage.getItem("token");

  useEffect(() => {
    if (local_token && !token) {
      setToken(local_token)
    }
  }, []);
  return token || local_token ? children : <Navigate to="/Login" />;
};
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch(actions.TokenSetter(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
