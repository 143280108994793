import { Edit, LinkRounded } from "@mui/icons-material";
import { useState } from "react";
import styles from "./Post.module.css";
import { Card, Drawer } from "antd";
const Post = (props) => {
  const { image, description, links, editmode, title, onEdit } = props;
  const loaded = image || title || links;
  const [openLinks, setOpenLinks] = useState(false);
  return (
    <>
      <a
        className={[styles.Post, !image ? styles.Loading : null].join(" ")}
        href={Array.isArray(links) && links.length === 1 ? links[0].link : null}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          if (editmode && loaded) {
            e.preventDefault();
            onEdit();
          } else if (Array.isArray(links) && links.length > 1) {
            setOpenLinks(true);
          }
        }}
        style={image && { backgroundImage: `url(${image})` }}
      >
        {/* <img
        width="100%"
        height="100%"
        style={{display : image ? 'block' : 'none'}}
        className={styles.Image}
        src={image}
        alt={description}
      /> */}
        {Array.isArray(links) && links.length > 0 && (
          <div className={styles.LinkIcon}>
            {editmode ? (
              <Edit sx={{ fill: "white", width: "1rem" }} />
            ) : (
              <LinkRounded
                sx={{
                  fill: "white",
                  width: "1rem",
                  transform: " rotateZ(45deg)",
                }}
              />
            )}
          </div>
        )}
      </a>
      <Drawer
        title="لینک‌ها"
        onClose={() => {
          setOpenLinks(false);
        }}
        open={openLinks}
        placement="bottom"
      >
        {Array.isArray(links) &&
          links.map((link) => {
            return (
              <a href={link.link} target="_blank">
                <Card style={{marginBottom : '0.5rem'}}>
                  <Card.Meta
                    title={link.title}
                    avatar={
                      <LinkRounded
                        sx={{
                          width: "1rem",
                          transform: " rotateZ(45deg)",
                        }}
                      />
                    }
                  />
                </Card>
              </a>
            );
          })}
      </Drawer>
    </>
  );
};
export default Post;
